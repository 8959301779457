import { Injectable, Injector } from '@angular/core';
import { ErrorPopupComponent } from "../error-popup.component";
import { BaseAE } from "../../../../helpers/base-ae/base-ae.class";
import { NgElement, WithProperties } from "@angular/elements";
import { TPopupErrorType } from "../errors-popup.catalog";

@Injectable({
    providedIn: 'any'
})
export class ErrorPopupService extends BaseAE {
    private popupEl: (NgElement & WithProperties<ErrorPopupComponent>) | undefined;

    constructor(private injector: Injector) {
        super('robocode-error-popup-ae', injector, ErrorPopupComponent);
    }

    override show(errorType: TPopupErrorType): this {
        if (!!this.popupEl) {
            this.hide();
        }

        this.popupEl = document.createElement(this.componentName) as any;
        this.popupEl?.addEventListener('closeModal', () => {
            this.hide();
        });

        if (this.popupEl instanceof Node) {
            this.popupEl.errorType = errorType;
            this.getParent().appendChild(this.popupEl);
        }

        return this;
    }

    override hide(): this {
        if (!this.popupEl) {
            return this;
        }
        this.getParent().removeChild(this.popupEl);
        this.popupEl = undefined;
        return this;
    }
}
