import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  template: ''
})
export abstract class BasePopupComponent {
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
    protected abstract componentSelector: string;

    onCloseModal($event: MouseEvent): void {
        let modal: Element | null = ($event.target as HTMLElement).closest(this.componentSelector);
        if (modal) {
            modal.remove();
        } else {
            this.closeModal.emit();
        }
    }

    stopPropagation(e: MouseEvent): void {
        e.stopPropagation();
    }
}
