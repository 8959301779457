import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CloseIconComponent } from "../../../svg/close-icon/close-icon.component";
import { SafeHtmlPipe } from "../../../pipes/safe-html.pipe";
import { errorsPopupCatalog, TPopupErrorType } from "./errors-popup.catalog";
import { BasePopupComponent } from "../base-popup/base-popup.component";
import { DefaultOverlayComponent } from "../../default-overlay/default-overlay.component";

@Component({
    selector: 'robocode-error-popup',
    standalone: true,
    imports: [CloseIconComponent, SafeHtmlPipe, DefaultOverlayComponent],
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.scss', '../base-popup/base-popup.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPopupComponent extends BasePopupComponent {
    @Input({ required: true }) errorType!: TPopupErrorType;
    protected readonly errorsPopupCatalog: Record<TPopupErrorType, string> = errorsPopupCatalog;
    protected componentSelector: string = 'robocode-error-popup';
}
